<template>
  <div>
    <el-form ref="form" size="small" :model="form" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="退款原因">
            <el-cascader
              ref="reasonCascader"
              v-model="form.reasonList"
              :options="reasonOptions"
              :props="reasonProps"
              collapse-tags
              clearable
            >
              <template #default="{ data }">
                <span>{{ data.labelName }}</span>
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="启用状态">
            <el-select
              v-model="form.status"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-button type="primary" :loading="queryLoading" @click="handleQuery">查询</el-button>
            <el-button @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label-width="0">
            <el-button type="primary" size="small" @click="handleOpenDialog()">新增</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vxe-table
      ref="table"
      :max-height="maxHeight"
      :loading="queryLoading"
      align="center"
      :data="tableData"
      :show-overflow="false"
      :scroll-y="{ enabled: false }"
      :scroll-x="{ enabled: false }"
      :column-config="{ resizable: true }"
    >
      <vxe-column :field="isChinese ? 'reasonCodeFirstCn' : 'reasonCodeFirstEn'" title="一级退款原因" min-width="80" />
      <vxe-column :field="isChinese ? 'reasonCodeSecondCn' : 'reasonCodeSecondEn'" title="二级退款原因" min-width="80" />
      <vxe-column field="delFlag" title="启用状态" width="60">
        <template #default="{ row }">
          <el-tag v-if="row.delFlag === 0" type="success">启用</el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </vxe-column>
      <vxe-column field="reasonCodeAmazon" title="Amazon" min-width="65" />
      <vxe-column field="reasonCodeEbay" title="eBay" min-width="80" />
      <vxe-column field="reasonCodeWalmart" title="Walmart" min-width="80" />
      <vxe-column field="reasonCodeSw" title="standwebsite" min-width="80" />
      <vxe-column title="操作" width="80">
        <template #default="{ row }">
          <el-button type="text" @click="handleOpenDialog(row)">编辑</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging :pager="pager" end @update="handlePagerUpdate" />
    <add-or-edit-dialog
      :visible.sync="addOrEditDialogVisible"
      :reason-options="reasonOptions"
      :status-options="statusOptions"
      :language="language"
      :row.sync="currentRow"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import Paging from '@/components/Paging/index.vue'
import AddOrEditDialog from './AddOrEditDialog.vue'
import { selectMakeLabel } from '@/api/service-management'
import { getReasonCodeList } from '@/api/omsorder'
import Cookies from 'js-cookie'
export default {
  name: 'ReturnReasonManage',
  components: {
    Paging,
    AddOrEditDialog
  },
  data() {
    return {
      language: this.$store.getters.language,
      form: {
        reasonList: [],
        status: ''
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      queryLoading: false,
      reasonOptions: [],
      reasonProps: {
        emitPath: false,
        multiple: true,
        value: 'id',
        label: 'labelName',
        children: 'childNodes'
      },
      statusOptions: [
        {
          label: '启用',
          value: 0
        },
        {
          label: '禁用',
          value: 1
        }
      ],
      tableData: [],
      maxHeight: 520,
      currentRow: null,
      addOrEditDialogVisible: false
    }
  },
  computed: {
    isChinese() {
      return this.language === 'zh-CN'
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    const erplang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
    this.language = erplang || lang
    this.getReasonOptions()
    this.handleQuery()
  },
  mounted() {
    this.calcHeight()
    window.addEventListener('resize', this.calcHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcHeight)
  },
  methods: {
    async getReasonOptions() {
      const { datas = [] } = await selectMakeLabel('AMAZON', 1)
      this.reasonOptions = datas
    },
    calcHeight() {
      // 计算表格高度
      // 屏幕高度 - 50px
      const clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
      const formHeight = this.$refs.form.$el.getBoundingClientRect().height
      const resetHeight = clientHeight - 50 - formHeight - 40 - 80
      this.maxHeight = resetHeight < 520 ? 520 : resetHeight
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.queryChange) {
        this.pager.current = 1
      }
      const selectedReasons = this.$refs.reasonCascader?.getCheckedNodes().reduce((res, item) => {
        const path = item.pathNodes || []
        if (path.length) {
          const parentName = path[0].data.labelNameEN
          if (!res[parentName]) {
            res[parentName] = []
          }
          res[parentName].push(item.data.labelNameEN)
        }
        return res
      }, {})
      const { datas = {}} = await getReasonCodeList({
        reasonMap: selectedReasons,
        status: this.form.status
      }, this.pager).finally(() => {
        this.queryLoading = false
      })
      const { pager = {}, records = [] } = datas
      this.tableData = records
      this.pager.total = pager.total
    },
    handleReset() {
      this.form = {
        reasonList: [],
        status: ''
      }
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    handleOpenDialog(row = null) {
      this.currentRow = row
      this.addOrEditDialogVisible = true
    }
  }
}
</script>

<style scoped>
::v-deep .el-button {
  min-width: 80px;
}

::v-deep .el-button--text {
  min-width: auto;
}

::v-deep .vxe-cell--title, ::v-deep .vxe-cell--label {
  word-break: break-word;
}
</style>
